import React, { useState } from 'react';
import { FeedbackButton, FeedbackBtn, FeedbackText } from 'features/feedback/ui/styles';
import { FeedbackModal } from 'features/feedback/ui/feedback-modal';
import { MailOutlined } from '@ant-design/icons';

export const Feedback = () => {
  const [openModal, setOpenModal] = useState(false);

  const showModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <FeedbackButton>
        <FeedbackBtn onClick={showModal}>
          <MailOutlined style={{ fontSize: '20px', marginRight: '13px' }} />
          <FeedbackText>Свяжитесь с нами</FeedbackText>
        </FeedbackBtn>
      </FeedbackButton>
      <FeedbackModal setOpenModal={setOpenModal} openModal={openModal} />
    </>
  );
};
