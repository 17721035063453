import React, { FC } from 'react';
import { MailFilled, PhoneFilled, SkypeFilled, UnorderedListOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { defaultMessage } from 'shared/config';
import { AssigneeDataType } from 'shared/types/common';
import { CustomModal } from 'shared/ui/modals/custom-modal';
import { LocationIcon } from 'shared/icons/LocationIcon';
import { TelegramIcon } from 'shared/icons/TelegramIcon';
import {
  MainInfoWrapper,
  ManagerContactLink,
  ManagerContactText,
  ManagerInfoItem,
  ManagerInfoValueWrapper,
} from './styles';

type ManagerModalProps = {
  openModal: boolean;
  title: string;
  setOpenModal: (isOpen: boolean) => void;
  avatar: string;
  name: string;
  data: AssigneeDataType;
};

type InfoListType = {
  title: string;
  icon: JSX.Element;
  link?: string;
};

export const ManagerModal: FC<ManagerModalProps> = ({
  openModal,
  title,
  setOpenModal,
  data,
  name,
  avatar,
}) => {
  const {
    email = '',
    skype = '',
    phone = '',
    telegram = '',
    position = defaultMessage,
    location = defaultMessage,
  } = data;

  const allInfoList: InfoListType[] = [
    {
      title: email,
      link: `mailto:${email}`,
      icon: <MailFilled />,
    },
    {
      title: phone,
      link: `tel:${phone}`,
      icon: <PhoneFilled />,
    },
    {
      title: skype,
      link: `Skype:${skype}`,
      icon: <SkypeFilled />,
    },
    {
      title: telegram,
      link: `https://t.me/${telegram?.slice(1)}`,
      icon: <TelegramIcon size={14} />,
    },
    {
      title: location,
      icon: <LocationIcon />,
    },
    {
      title: position,
      icon: <UnorderedListOutlined />,
    },
  ];

  const infoList = allInfoList.filter((item) => item.title);

  return (
    <CustomModal title={title} setOpenModal={setOpenModal} openModal={openModal}>
      <div>
        <MainInfoWrapper>
          <Avatar size={80} src={avatar} alt="Avatar" />
          <h3>{name}</h3>
        </MainInfoWrapper>
        <div>
          {infoList.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ManagerInfoItem key={index}>
              {item.icon}
              <ManagerInfoValueWrapper>
                {item.link ? (
                  <ManagerContactLink href={item.link}>{item.title}</ManagerContactLink>
                ) : (
                  <ManagerContactText>{item.title}</ManagerContactText>
                )}
              </ManagerInfoValueWrapper>
            </ManagerInfoItem>
          ))}
        </div>
      </div>
    </CustomModal>
  );
};
