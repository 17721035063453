import Modal from 'antd/lib/modal/Modal';
import { CSSProperties, ReactNode } from 'react';

type CustomModalProps = {
  style?: CSSProperties;
  children: ReactNode;
  openModal: boolean;
  title: string;
  setOpenModal: (isOpen: boolean) => void;
};

export const CustomModal = ({
  style,
  children,
  openModal,
  setOpenModal,
  title,
}: CustomModalProps) => {
  const handleCancel = () => {
    setOpenModal(false);
  };

  return (
    <Modal style={style} open={openModal} title={title} onCancel={handleCancel} footer={false}>
      {children}
    </Modal>
  );
};
