import { AxiosPromise } from 'axios';

import { apiInstance } from 'shared/api/base';

import {
  SickDaysParams,
  SickDaysResponse,
  SuppliesResponse,
  VacationParams,
  VacationResponse,
  SuppliesParams,
} from 'shared/api/statistic/types';

import { API_URL } from 'shared/config';

export const getSickDays = (params: SickDaysParams): AxiosPromise<SickDaysResponse> => {
  const url = `${API_URL}/sickdays/remain/${params.egar_id}?list_id=${params.list_id}`;

  return apiInstance.get(url);
};

export const getVacationDays = (params: VacationParams): AxiosPromise<VacationResponse> => {
  const url = `${API_URL}/vacations/remain/${params.list_id}/${params.egar_id}?job_start_date=${params.onboard_date}`;

  return apiInstance.get(url);
};

export const getSuppliesCount = (params: SuppliesParams): AxiosPromise<SuppliesResponse> => {
  const url = `${API_URL}/supplies/remain/${params.egar_id}?list_id=${params.list_id}`;

  return apiInstance.get(url);
};
