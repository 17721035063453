import { useDispatch, useSelector } from 'react-redux';
import { clearDays } from 'entities/statistics';
import { useEffect } from 'react';
import { useSickDaysStatistic, useSupplieesStatistic, useVacationStatistic } from 'shared/hooks';

let cacheUserName = '';

export const useStatistics = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearDays());
  }, []);

  const user = useSelector((state: RootState) => state.user.user);

  const { mutate: sickDaysMutation } = useSickDaysStatistic();
  const { mutate: vocationDaysMutation } = useVacationStatistic();
  const { mutate: suppliesDaysMutation } = useSupplieesStatistic();

  useEffect(() => {
    if (Object.keys(user).length && cacheUserName !== user.egar_id) {
      cacheUserName = user.egar_id;
      const { list_id, onboard_date, egar_id } = user;
      sickDaysMutation({ list_id, egar_id });
      vocationDaysMutation({ list_id, egar_id, onboard_date });
      suppliesDaysMutation({ list_id, egar_id });
    }
  }, [user]);
};
