import { User } from 'shared/api/user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  username: string | null;
  user: User;
};

export const initialState: InitialState = {
  username: null,
  user: {} as User,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },

    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },

    updateUser: (state, action: PayloadAction<Partial<User>>) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },

    logout: (state) => {
      state.username = null;
      state.user = {} as User;
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    },
  },
});

export const { setUsername, setUser, logout, updateUser } = userSlice.actions;

export const { reducer } = userSlice;
