import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { getSickDays, getSuppliesCount, getVacationDays } from 'shared/api/statistic';
import { setSickDays, setSuppliesCount, setVacationDays } from 'entities/statistics';
import { useDispatch } from 'react-redux';
import { getUserInfo } from 'shared/api/user';
import { setUser } from 'entities/user';
import { useAppSelector } from 'shared/hooks/useRedux';
import { AxiosError } from 'axios';

export const useSickDaysStatistic = () => {
  const dispatch = useDispatch();
  return useMutation(getSickDays, {
    onSuccess: ({ data }) => dispatch(setSickDays(data.sick_day_remain)),
    onError: () => dispatch(setSickDays(-1)),
  });
};

export const useVacationStatistic = () => {
  const dispatch = useDispatch();
  return useMutation(getVacationDays, {
    onSuccess: ({ data }) => dispatch(setVacationDays(data.vacation_day_remain)),
    onError: () => dispatch(setVacationDays(-1)),
  });
};

export const useSupplieesStatistic = () => {
  const dispatch = useDispatch();
  return useMutation(getSuppliesCount, {
    onSuccess: ({ data }) => dispatch(setSuppliesCount(data.remain)),
    onError: () => dispatch(setSuppliesCount(-1)),
  });
};

export const useUserInfo = <T>(query: UseQueryResult<T, AxiosError<unknown, any>>) => {
  const dispatch = useDispatch();
  const egar_id = useAppSelector((state) => state.user.user.egar_id);
  return useQuery(['user-info'], () => getUserInfo(egar_id), {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess: async (response) => {
      dispatch(setUser(response.data));
      await query.refetch();
    },
  });
};
