import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { CustomModal } from 'shared/ui/modals';
import TextArea from 'antd/es/input/TextArea';
import { Button, Form, notification } from 'antd';
import { useSendFeedback } from 'features/feedback/model';

type FormType = { feedback: string };

interface FeedbackModalProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export const FeedbackModal = ({ openModal, setOpenModal }: FeedbackModalProps) => {
  const [form] = Form.useForm<FormType>();
  const { mutate: sendFeedback, isLoading, isSuccess } = useSendFeedback();

  const onFinishHandler = (values: FormType) => {
    sendFeedback(values.feedback);
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenModal(false);
      notification.success({
        message: 'Заявка успешно создана',
        description: 'Спасибо за обращение! Мы скоро свяжемся в ответ.',
      });
      form.resetFields();
    }
  }, [isSuccess]);

  return (
    <CustomModal title="Рассказать о проблеме" openModal={openModal} setOpenModal={setOpenModal}>
      <Form form={form} onFinish={onFinishHandler}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Это поле обязательно для заполнения',
            },
          ]}
          name="feedback"
        >
          <TextArea
            required
            placeholder="Опишите Вашу проблему"
            showCount
            allowClear
            maxLength={1000}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        <Form.Item
          style={{ display: 'flex', justifyContent: 'right', paddingTop: '20px', margin: 0 }}
        >
          <Button loading={isLoading} htmlType="submit" type="primary">
            Отправить
          </Button>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};
