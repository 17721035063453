import React from 'react';
import { Spinner } from 'shared/ui/suspense/styles';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const loading = <LoadingOutlined style={{ fontSize: 36 }} spin />;

function Suspense() {
  return (
    <Spinner>
      <Spin indicator={loading} />
    </Spinner>
  );
}

export default Suspense;
