import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from 'widgets/main-layout';
import SuspenseLoader from 'shared/ui/suspense';

const AuthPage = lazy(() => import('./auth'));
const ProfilePage = lazy(() => import('./profile'));
const VacationPage = lazy(() => import('./vacations'));
const SickDaysPage = lazy(() => import('./sick-days'));
const TimeoffPage = lazy(() => import('./timeoff'));
const SuppliesPage = lazy(() => import('./supplies'));
const EmployeeMapPage = lazy(() => import('./employee-map'));
const OrgchartPage = lazy(() => import('./orgchart'));
const VacanciesPage = lazy(() => import('./vacancies'));
const CalendarPage = lazy(() => import('./calendar'));
const OverhoursPage = lazy(() => import('./overhours'));

export function Routing() {
  return (
    <Routes>
      <Route path="/auth" element={<AuthPage />} />

      <Route path="/" element={<MainLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <ProfilePage />
            </Suspense>
          }
        />
        <Route
          path="/vacations"
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <VacationPage />
            </Suspense>
          }
        />
        <Route
          path="/sick-days"
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <SickDaysPage />
            </Suspense>
          }
        />
        <Route
          path="/timeoff"
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <TimeoffPage />
            </Suspense>
          }
        />
        <Route
          path="/supplies"
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <SuppliesPage />
            </Suspense>
          }
        />
        <Route
          path="/employee-map"
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <EmployeeMapPage />
            </Suspense>
          }
        />
        <Route
          path="/sick-days"
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <SickDaysPage />
            </Suspense>
          }
        />
        <Route
          path="/orgchart"
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <OrgchartPage />
            </Suspense>
          }
        />
        <Route
          path="/vacancies"
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <VacanciesPage />
            </Suspense>
          }
        />
        <Route
          path="/calendar"
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <CalendarPage />
            </Suspense>
          }
        />
        <Route
          path="/overhours"
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <OverhoursPage />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

export default Routing;
