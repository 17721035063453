import { AssigneeDataType, Breakpoints } from 'shared/types/common';

// export const isDevEnv = NODE_ENV === "development";
// export const isProdEnv = NODE_ENV === "production";

export const API_URL =
  window._env_.REACT_APP_API_URL || process.env.REACT_APP_API_URL || 'http://192.168.111.141/api';
export const BASE_URL =
  window._env_.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL || 'http://192.168.111.10:8081';

export const CLIENT_ID = window._env_.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID;

export const CLIENT_SECRET =
  window._env_.REACT_APP_CLIENT_SECRET || process.env.REACT_APP_CLIENT_SECRET;

export const dateFormat = 'DD.MM.YYYY';

export const breakpoints: Breakpoints = {
  xs: '480px',
  sm: '500px',
  md: '700px',
  lg: '900px',
  xl: '1230px',
  xxl: '1600px',
  antXs: '480px',
  antSm: '576px',
  antMd: '768px',
  antLg: '992px',
  antXl: '1200px',
  antXxl: '1600px',
};

export const idsOfCustomFields = {
  Аватар: 'd01ad323-e69b-4413-9d57-256613e62ee0',
  Имя: '4caa94ed-8bee-422f-8c35-088faf321fba',
  Фамилия: '6efec6e2-1645-49d4-973b-8a15f93c407e',
  Локация: 'bf015492-98e8-42f9-91a4-c802c988283a',
  Отчество: 'f73b08e3-03f2-4c8b-9d27-84347daa716a',
  Должность: '232c38d6-9c7c-44b8-9b31-73dfe7df4b65',
  'Email рабочий': '5c6128d2-1c1f-420d-890a-e85afd61b123',
  Skype: '6f023e63-c487-40cb-8eb3-bb582557e4a0',
  Телеграм: '7dcb1177-6071-40c0-83dc-5c1b45cc7a3c',
  'Тел. моб': '159a46bb-b8f7-41c8-a226-2a523e9b7403',
};

export const customFieldIds = {
  work_email: '5c6128d2-1c1f-420d-890a-e85afd61b123',
  whatsapp: 'eb239dbb-41eb-4589-b3b6-2af86edb5895',
  phone: '159a46bb-b8f7-41c8-a226-2a523e9b7403',
  telegram: '7dcb1177-6071-40c0-83dc-5c1b45cc7a3c',
  skype: '6f023e63-c487-40cb-8eb3-bb582557e4a0',
};

export const defaultModalData: AssigneeDataType = {
  id: '',
  name: '',
  avatar: '',
  location: '',
  phone: '',
  email: '',
  skype: '',
  telegram: '',
  position: '',
};

export const defaultMessage = 'Не указано';
