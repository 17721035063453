import React from 'react';
import { Col } from 'antd';
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getUserInfo } from 'shared/api/user';
import { setUser, setUsername } from 'entities/user';
import { getUsernameFromToken } from 'shared/utils/common';
import Suspense from 'shared/ui/suspense';
import { Navigation } from 'features/navigation/ui';
import { useStatistics } from 'widgets/main-layout/hooks/useStatistics';
import { MainContainer } from 'widgets/main-layout/ui/styles';
import { Feedback } from 'features/feedback';
import { useAppSelector } from 'shared/hooks';

function MainLayout() {
  const dispatch = useDispatch();
  const egar_id = useAppSelector((state: RootState) => state.user.user.egar_id);

  const authUsername = useSelector((state: RootState) => state.user.username);
  const isAuth = Boolean(authUsername);

  const { isLoading, isError, isSuccess } = useQuery(
    ['user-info', authUsername],
    () => getUserInfo(egar_id || ''),
    {
      enabled: isAuth,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        dispatch(setUser(response.data));
      },
    },
  );

  useStatistics();

  if (!isAuth) {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      const username = getUsernameFromToken(accessToken);
      dispatch(setUsername(username));
    } else {
      return <Navigate to="/auth" />;
    }
  }

  if (isLoading || !isSuccess) {
    return <Suspense />;
  }

  if (isError) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    return <Navigate to="/auth" />;
  }

  return (
    <MainContainer justify="center">
      <Col xs={23} md={23} lg={23} xl={21} xxl={17}>
        <Navigation />
        <Outlet />
      </Col>
      <Feedback />
    </MainContainer>
  );
}

export default MainLayout;
