import jwtDecode from 'jwt-decode';
import moment from 'moment/moment';
import { Typography } from 'antd';

/**
 * Парсит токен и возвращает только логин
 * @param token
 */
export const getUsernameFromToken = (token: string): string => {
  const { preferred_username } = jwtDecode<{ preferred_username: string }>(token);
  return preferred_username;
};

export const msToDate = (ms: string | number | undefined): string => {
  if (!ms) return '';
  return moment(Number(ms)).format('DD.MM.YYYY');
};

export const { Title, Paragraph } = Typography;

type TRecord = Record<string, any>;

export const alphabeticalSorting = <T extends Array<TRecord>>(array: T, field: string): T =>
  array.sort((a, b) => (a[field] >= b[field] ? 1 : -1));

/**
 * Склонение заголовка, в зависимости от переданого значения number
 *
 * Пример
 *
 * 1, ['День', 'Дня', 'Дней'] -> 1 День
 * 6, ['День', 'Дня', 'Дней'] -> 6 Дней
 * 102, ['День', 'Дня', 'Дней'] -> 102 дня
 * // https://ru.stackoverflow.com/questions/1455831/%D0%94%D0%B5%D0%BD%D1%8C-%D0%B4%D0%BD%D1%8F-%D0%B4%D0%BD%D0%B5%D0%B9-js-%D0%B2%D1%8B%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5
 *
 * @param number
 * @param titles
 */
export const createLabel = (number: number, titles: Array<string>) => {
  const titlesVariantIndex = [2, 0, 1, 1, 1, 2];
  return `${
    titles[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : titlesVariantIndex[number % 10 < 5 ? number % 10 : 5]
    ]
  }`;
};
