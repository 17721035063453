import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'shared/config';

type UseMediaValue = {
  isDesktop: boolean;
  isBigScreen: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isMobileS: boolean;
};

export const useMedia = (): UseMediaValue => {
  const isDesktop = useMediaQuery({ query: `(max-width: ${breakpoints.xl})` });
  const isBigScreen = useMediaQuery({ query: `(min-width: ${breakpoints.xxl})` });
  const isTablet = useMediaQuery({ query: `(max-width: ${breakpoints.md})` });
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.sm})` });
  const isMobileS = useMediaQuery({ query: `(max-width: ${breakpoints.xs})` });

  return { isDesktop, isBigScreen, isTablet, isMobile, isMobileS };
};
