import axios from 'axios';
import { API_URL, CLIENT_ID } from 'shared/config';
import qs from 'qs';

export const apiInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

const openIdConnectUrl = `${API_URL}/login`;

const refreshToken = () => {
  const body = {
    grant_type: 'refresh_token',
    client_id: CLIENT_ID,
    refresh_token: localStorage.getItem('refresh_token'),
  };

  return axios.post(openIdConnectUrl, qs.stringify(body), {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
};

apiInstance.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        const access = localStorage.getItem('access_token');
        const refresh = localStorage.getItem('access_token');

        if (access || refresh) {
          const response = await refreshToken();
          const tokens = response.data;

          localStorage.setItem('access_token', tokens?.access_token);
          localStorage.setItem('refresh_token', tokens?.refresh_token);
        }
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error('error', err);

        /**
         * Когда refresh_token протухает, необходимо,
         * чтобы пользователь ввел заново логин и пароль
         */
        if (err.response.status === 401 || err.response.status === 400) {
          localStorage.clear();
          window.location.reload();
        }
      }
      return apiInstance.request(originalRequest).catch(() => {
        throw error;
      });
    }
    return Promise.reject(error);
  },
);
