import styled from 'styled-components';

export const StatisticsItemsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const StatisticsItemsField = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-left: 1em;
`;

export const StatisticsItemsTitle = styled.h5`
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1;
`;

export const StatisticsItemsDesc = styled.p`
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: #636e72;
`;
