import styled from 'styled-components';
import { breakpoints } from 'shared/config';

export const MainInfoWrapper = styled.div`
  text-align: center;
`;

export const ManagerInfoItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  margin: 20px 30px;
  padding-bottom: 3px;

  @media (max-width: ${breakpoints.sm}px) {
    margin: 20px 0;
  }
`;

export const ManagerInfoValueWrapper = styled.div`
  border-bottom: 1px solid #a4a4a4;
  width: 100%;
  margin-left: 7px;
`;

const textStyle = () => `
  display: inline-block;
  color: rgba(0, 0, 0, 0.85)
`;

export const ManagerContactLink = styled.a`
  ${textStyle()}
`;

export const ManagerContactText = styled.span`
  ${textStyle()}
`;
