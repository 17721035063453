import { notification } from 'antd';
import { NotificationInstance } from 'antd/lib/notification';

interface NotificationProps {
  title: string;
  description: string;
  duration?: number;
  type?: keyof NotificationInstance;
}

const openNotification = ({
  duration = 0,
  description,
  type = 'error',
  title,
}: NotificationProps) => {
  notification[type]({ message: title, description, duration });
};

export const useNotifications = () => (args: NotificationProps) => openNotification(args);
