import { MenuProps } from 'antd';
import {
  ProfileOutlined,
  LogoutOutlined,
  UserOutlined,
  UserSwitchOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import { LocationIcon } from 'shared/icons/LocationIcon';
import React from 'react';

export const navItem: MenuProps['items'] = [
  { label: 'Профиль', key: '/', icon: <UserOutlined /> },
  // { label: 'Отпуска', key: '/vacations', icon: <CarOutlined /> },
  // { label: 'Сикдеи', key: '/sick-days', icon: <AlertOutlined /> },
  // { label: 'Больничные', key: '/timeoff', icon: <AlertOutlined /> },
  // { label: 'Обеспечение', key: '/supplies', icon: <DesktopOutlined /> },
  { label: 'Оргструктура', key: '/orgchart', icon: <ProfileOutlined /> },
  {
    label: 'Карта Сотрудников',
    key: '/employee-map',
    icon: (
      <span>
        <LocationIcon />
      </span>
    ),
  },
  { label: 'Ярмарка вакансий', key: '/vacancies', icon: <UserSwitchOutlined /> },
  { label: 'Календарь', key: '/calendar', icon: <CalendarOutlined /> },
  { label: 'Выйти', key: 'logout', icon: <LogoutOutlined /> },
];
