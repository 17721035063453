import type { AxiosPromise } from 'axios';
import { CLIENT_ID, API_URL } from 'shared/config';
import qs from 'qs';
import { apiInstance } from 'shared/api/base';
import type { OpenIdConnectResponse, User, OpenIdConnectPayload } from './types';

const openIdConnectUrl = `${API_URL}/login`;

export const getToken = (params?: OpenIdConnectPayload): AxiosPromise<OpenIdConnectResponse> => {
  const payload = { ...params, grant_type: 'password', client_id: CLIENT_ID };
  return apiInstance.post(openIdConnectUrl, qs.stringify(payload), {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
};
// TODO edit logic with username func - done
export const getUserInfo = (egar_id: string): AxiosPromise<User> =>
  apiInstance.get(`/profile/${egar_id}`);
