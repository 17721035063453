import React from 'react';
import { Routing } from 'pages';
import { BrowserRoutingProvider, QueryClientProvider, ReduxProvider } from 'app/providers';

function App() {
  return (
    <ReduxProvider>
      <BrowserRoutingProvider>
        <QueryClientProvider>
          <Routing />
        </QueryClientProvider>
      </BrowserRoutingProvider>
    </ReduxProvider>
  );
}

export default App;
