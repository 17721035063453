import { Button } from 'antd';
import styled from 'styled-components';

export const FeedbackButton = styled.div`
  position: fixed;
  top: 29px;
  transition: 0.6s all;
  right: -131px;
  display: flex;
  &:hover {
    right: 0;
    transition: 0.6s all;
  }
`;

export const FeedbackBtn = styled(Button)`
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FeedbackText = styled.div`
  margin: 0;
  display: inline-block;
`;
