import axios, { AxiosError } from 'axios';
import { NetworkError } from 'shared/types/common';
import { useNotifications } from 'shared/hooks/index';

const defaultTitle = 'Ошибка';
const defaultMessage = 'Неизвестная ошибка. Обратитесь в поддержку';

type ErrorDescriptionType = {
  title: string;
  description: string;
};

const errorDescription: ErrorDescriptionType = {
  title: defaultTitle,
  description: defaultMessage,
};

export const useNetworkError = (
  error: Error | AxiosError,
  errorDesc: ErrorDescriptionType = errorDescription,
) => {
  const openNotification = useNotifications();
  if (axios.isAxiosError(error) && error.response) {
    const axiosError = error.response.data as NetworkError;
    openNotification({
      title: axiosError?.error?.title || errorDesc.title,
      description: axiosError?.error?.message || errorDesc.description,
    });
  } else {
    throw error;
  }
};
