import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  sickDays?: number;

  vacationDays?: number;

  suppliesCount?: number;
};

export const initialState: InitialState = {
  sickDays: undefined,

  vacationDays: undefined,

  suppliesCount: undefined,
};

export const statisticsSlice = createSlice({
  name: 'statistics',

  initialState,

  reducers: {
    setSickDays: (state, action: PayloadAction<number>) => {
      state.sickDays = action.payload;
    },

    setVacationDays: (state, action: PayloadAction<number>) => {
      state.vacationDays = action.payload;
    },

    setSuppliesCount: (state, action: PayloadAction<number>) => {
      state.suppliesCount = action.payload;
    },

    clearStatistic: (state, action: PayloadAction<keyof InitialState>) => {
      const { payload } = action;

      state[payload] = initialState[payload];
    },

    clearDays: (state) => {
      state.sickDays = initialState.sickDays;

      state.suppliesCount = initialState.suppliesCount;

      state.vacationDays = initialState.vacationDays;
    },
  },
});

export const { setSickDays, setVacationDays, setSuppliesCount, clearDays, clearStatistic } =
  statisticsSlice.actions;
