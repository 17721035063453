import { configureStore } from '@reduxjs/toolkit';

import { userSlice } from 'entities/user';
import { statisticsSlice } from 'entities/statistics';
import { calendarSlice } from 'entities/calendar';

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    statistics: statisticsSlice.reducer,
    calendar: calendarSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
