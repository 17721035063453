import { useMutation } from '@tanstack/react-query';
import { sendFeedback } from 'shared/api/feedback';
import { useAppSelector } from 'shared/hooks';
import { AxiosError } from 'axios';
import { useNetworkError } from 'shared/hooks/useNetworkError';

export const useSendFeedback = () => {
  const { egar_id } = useAppSelector((state) => state.user.user);

  return useMutation({
    mutationFn: async (message: string) => sendFeedback(egar_id, message),
    retry: 0,
    onError: (error: AxiosError) =>
      useNetworkError(error, {
        description: 'Что-то пошло не так, попробуйте позже',
        title: 'Ошибка',
      }),
  });
};
